
import { defineComponent, reactive, toRefs } from 'vue';
import { RegistrationForm } from '@/models/Models';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { email, required, helpers } from '@vuelidate/validators';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default defineComponent({
    components: {
        LanguageSwitcher
    },
    setup() {
        const { t } = useI18n();

        const form = reactive<RegistrationForm>(new RegistrationForm());

        const variables = reactive({
            incorrectMobile: false,
            passwordFieldType: 'password',
            confirmPasswordFieldType: 'password'
        });

        const rules = {
            email: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required),
                email: helpers.withMessage(t('registration.emailValidationError'), email)
            },
            password: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required),
                goodPassword: helpers.withMessage(t('registration.passwordValidationError'), () => {
                    return (
                        form.password.length >= 8 &&
                        /[a-z]/.test(form.password) &&
                        /[A-Z]/.test(form.password) &&
                        /[0-9]/.test(form.password)
                    );
                })
            },
            confirmPassword: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required),
                passwordsEquals: helpers.withMessage(t('registration.passwordsNotEqual'), () => {
                    return form.password == form.confirmPassword;
                })
            },
            firstName: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required)
            },
            lastName: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required)
            },
            companyName: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required)
            },
            phoneNumber: {
                required: helpers.withMessage(t('registration.requiredValidationError'), required)
            }
        };

        const v$ = useVuelidate(rules, form);

        const mobileNumberChanged = () => {
            variables.incorrectMobile = false;
            form.phoneNumber = form.phoneNumber.replace(/[^\s+\-0-9]/g, '');
        }

        const submitForm = async () => {
            v$.value.$touch();
            if (v$.value.$invalid) {
                console.log(v$);
                return;
            } else {
                swal.showLoading();
                form.phoneNumber = form.phoneNumber.replace(/\D+/g, '');
                const newUserResponse = await api.newUserRegistration(form);
                if (newUserResponse.data?.errorCode == 800) {
                    swal.fire({
                        icon: 'error',
                        title: t('error-pop-up.oops'),
                        text: t('registration.errorMessage.emailExist')
                    });
                    return;
                }
                if (newUserResponse.hasValidationError && newUserResponse.validationErrorsList()?.length) {
                    const message = newUserResponse
                        .validationErrorsList()
                        ?.map(x => `Field '${x?.key}': ${x?.msg}`)
                        ?.join('\n');
                    if (message?.includes('Invalid Mobile')) {
                        variables.incorrectMobile = true;
                        swal.close();
                        return;
                    }
                    swal.fire({
                        icon: 'error',
                        text: message
                    });
                } else if (newUserResponse.errorMessage)
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: newUserResponse.errorMessage });
                else if (newUserResponse.data && !newUserResponse.data.token) {
                    swal.fire({
                        icon: 'success',
                        title: t('registration.wellDone'),
                        text: t('registration.weSentYouEmailConfirmation')
                    });
                    router.push({ name: 'login' });
                } else if (newUserResponse.data && newUserResponse.data.token) {
                    swal.close();
                    router.push({ name: 'registration-details', params: { token: newUserResponse.data.token } });
                }
            }
        };

        return {
            form,
            submitForm,
            mobileNumberChanged,
            v$,
            ...toRefs(variables)
        };
    }
});
